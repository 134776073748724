import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/Layout";
import BlogPostDetailsTemplate from "../components/BlogPostDetailsTemplate";
import { HTMLContent } from "../components/Content";

// markup
const IndexPage = (props) => {
  const data = props.data;
  const jsonData = data.articles.edges[0].node.articles;
  const langKey = data.markdownRemark.frontmatter.lang;
  const languages = data.site.siteMetadata.languages;
  const id = data.markdownRemark.frontmatter.id;
  const location = props.location;
  const description = data.markdownRemark.frontmatter.description;
  const title = data.markdownRemark.frontmatter.title;
  const date = data.markdownRemark.frontmatter.date;
  const html = data.markdownRemark.html;
  const tags = data.markdownRemark.frontmatter.tags;
  return (
    <Layout
      title={title}
      jsonData={jsonData}
      languages={languages}
      location={location}
      id={id}
      data={data}
      description={description}
    >
      {date}
      <title>{title}</title>
      <BlogPostDetailsTemplate
        contentComponent={HTMLContent}
        title={title}
        content={html}
        lang={langKey}
        tags={tags}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query AboutPageQuery($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
        languages {
          defaultLangKey
          langs
        }
      }
    }
    articles: allArticlesJson(filter: { title: { eq: "home" } }) {
      edges {
        node {
          articles {
            en
            zh
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        description
        id
        lang
        slug
        tags
        template
        title
      }
      html
    }
  }
`;
